import { css } from "aphrodite";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-styled-flexboxgrid";

import AuthForm from "layout/components/AuthForm";
import styles from "layout/styles";

import { Button } from "components/fl-ui";
import { FormGroup, Input } from "components/fl-ui/Form";

const copy = (
  <p className={css(styles.copyText)}>
    {"Already have an account? "}
    <Link className={css(styles.blueText)} to="/login">
      Sign in now
    </Link>
  </p>
);

const errorMessage = (
  <Fragment>
    <span className={css(styles.errorText)}>
      {`Registration failed. Please verify that the email address you are using isn't already registered.`}
    </span>
    <span className={css(styles.errorText)}>
      {`If you forgot your password, `}
      <Link className={css(styles.blueText)} to="/password/reset">
        click here
      </Link>
      .
    </span>
  </Fragment>
);

const footer = (
  <div className={css(styles.footerContainer)}>
    {"By creating an account you agree to our "}
    <Link
      className={css(styles.blueText)}
      to="https://bushelpowered.com/policies"
      rel="noopener noreferrer"
      target="_blank"
    >
      terms of service
    </Link>
  </div>
);

const SignUpForm = ({ formErrors, formValues, handleChange, handlePasswordFieldToggle, handleSubmit }) => {
  const {
    email,
    error,
    first_name,
    last_name,
    loading,
    password,
    passwordToggleText,
    passwordType,
    phone,
    postal_code,
  } = formValues;

  return (
    <AuthForm
      action="Create account"
      copy={copy}
      disabled={loading}
      error={error && errorMessage}
      footer={footer}
      label="Create an account to get a free trial"
      onChange={handleChange}
      onSubmit={handleSubmit}
    >
      <Row>
        <Col xs md={6}>
          <FormGroup label="First name">
            <Input
              autoFocus
              disabled={loading}
              hasError={formErrors.first_name}
              id="first_name"
              name="first_name"
              type="text"
              value={first_name}
            />
            {formErrors.first_name && <div className="help-inline">{formErrors.first_name}</div>}
          </FormGroup>
        </Col>

        <Col xs md={6}>
          <FormGroup label="Last name">
            <Input
              disabled={loading}
              hasError={formErrors.last_name}
              id="last_name"
              name="last_name"
              type="text"
              value={last_name}
            />
            {formErrors.last_name && <div className="help-inline">{formErrors.last_name}</div>}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs>
          <FormGroup label="Email">
            <Input disabled={loading} hasError={formErrors.email} id="email" name="email" type="email" value={email} />
            {formErrors.email && <div className="help-inline">{formErrors.email}</div>}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs md={6}>
          <FormGroup label="Phone number">
            <Input disabled={loading} hasError={formErrors.phone} id="phone" name="phone" type="text" value={phone} />
            {formErrors.phone && <div className="help-inline">{formErrors.phone}</div>}
          </FormGroup>
        </Col>

        <Col xs md={6}>
          <FormGroup label="Postal code">
            <Input
              disabled={loading}
              hasError={formErrors.postal_code}
              id="postal_code"
              name="postal_code"
              type="text"
              value={postal_code}
            />
            {formErrors.postal_code && <div className="help-inline">{formErrors.postal_code}</div>}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs>
          <FormGroup label="Password">
            <Input
              disabled={loading}
              hasError={formErrors.password}
              id="password"
              name="password"
              suffix={
                password && (
                  <Button className={css(styles.toggleText)} link onClick={handlePasswordFieldToggle}>
                    {passwordToggleText}
                  </Button>
                )
              }
              type={passwordType}
              value={password}
            />
            {formErrors.password && <div className="help-inline">{formErrors.password}</div>}
          </FormGroup>
        </Col>
      </Row>
    </AuthForm>
  );
};

SignUpForm.propTypes = {
  formErrors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handlePasswordFieldToggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SignUpForm;
