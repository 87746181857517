import React from "react";
import { Navigate } from "react-router-dom";

import { useAuth } from "collection/graphql/auth";
import NoMatchRouteLayout from "layout/NoMatchRouteLayout";

const LandingPageRedirect = () => {
  const { currentUser, isLoggedIn } = useAuth();
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return <Navigate to={currentUser.landingPage || "fields"} />;
};

export default [
  {
    path: "/",
    element: <LandingPageRedirect />,
  },

  {
    path: "*",
    element: <NoMatchRouteLayout />,
  },
];
