import { ApolloProvider } from "@apollo/client";
import { ConfigProvider, theme } from "@farmlogs/fl-ui";
import { isNative } from "mobile/mobileManager";
import PropTypes from "prop-types";
import React from "react";

import { AuthContextProvider } from "collection/graphql/auth";
import { marketingClient } from "collection/graphql/client";
import AnalyticsProvider from "context/AnalyticsProvider";
import LaunchDarklyProvider from "context/LaunchDarklyProvider";
import RedirectionProvider from "context/RedirectionProvider";

import ErrorBoundary from "components/fl-ui/Layout/ErrorBoundary";

const GlobalContextProvider = ({ children }) => {
  const uiConfig = {
    datePicker: {
      forceNative: isNative(),
      outputFormat: "yyyy-MM-dd",
    },
  };

  return (
    <ConfigProvider config={uiConfig} theme={theme}>
      <AuthContextProvider>
        <LaunchDarklyProvider>
          <ErrorBoundary>
            <ApolloProvider client={marketingClient}>
              <AnalyticsProvider>
                <RedirectionProvider>{children}</RedirectionProvider>
              </AnalyticsProvider>
            </ApolloProvider>
          </ErrorBoundary>
        </LaunchDarklyProvider>
      </AuthContextProvider>
    </ConfigProvider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default GlobalContextProvider;
