import useDocumentTitle from "app/hooks/useDocumentTitle";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "collection/graphql/auth";
import SignUpForm from "layout/components/SignUpForm";
import { getSignUpErrors, hasErrors } from "layout/utils";

const defaultValues = {
  email: "",
  error: false,
  first_name: "",
  last_name: "",
  loading: false,
  password: "",
  passwordToggleText: "SHOW",
  passwordType: "password",
  phone: "",
  postal_code: "",
};

const SignUp = () => {
  useDocumentTitle("Sign up");
  const navigate = useNavigate();
  const { register } = useAuth();
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(defaultValues);
  const { email, first_name, last_name, password, passwordToggleText, passwordType, phone, postal_code } = formValues;
  const handleChange = (e, formData) => setFormValues({ ...formValues, ...formData });
  const handlePasswordFieldToggle = () =>
    setFormValues({
      ...formValues,
      passwordToggleText: passwordToggleText === "SHOW" ? "HIDE" : "SHOW",
      passwordType: passwordType === "text" ? "password" : "text",
    });

  const onSuccess = () => {
    navigate("/fields/survey");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formErrors = getSignUpErrors(formValues);
    setFormErrors(formErrors);

    if (hasErrors(formErrors)) {
      return null;
    }

    setFormValues({ ...formValues, loading: true });

    const userRegistration = {
      email,
      first_name,
      last_name,
      password,
      phone,
      postal_code,
      passwordConfirm: password,
    };

    return register(userRegistration)
      .then(onSuccess)
      .catch(() => {
        setFormValues({ ...formValues, error: true, loading: false });
      });
  };

  return (
    <SignUpForm
      formErrors={formErrors}
      formValues={formValues}
      handleChange={handleChange}
      handlePasswordFieldToggle={handlePasswordFieldToggle}
      handleSubmit={handleSubmit}
    />
  );
};

export default SignUp;
