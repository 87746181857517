import MobileForcedAppUpdateGuard from "mobile/MobileForcedAppUpdateGuard";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";

import LoadingWrapper from "components/fl-ui/LoadingWrapper";

const Application = () => {
  // Add SSO contexts here

  return (
    <Suspense fallback={<LoadingWrapper isLoading />}>
      <MobileForcedAppUpdateGuard>
        <Outlet />
      </MobileForcedAppUpdateGuard>
    </Suspense>
  );
};

export default Application;
