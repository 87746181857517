import { css } from "aphrodite";
import _ from "lodash";
import { LeftButton } from "profit_and_loss/styles";
import React, { memo, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Row } from "react-styled-flexboxgrid";
import { ThemeProvider } from "styled-components";

import { useUserConfig } from "collection/graphql/config";
import { getCurrentSubscription } from "collection/graphql/subscription";
import withCropMutation from "hoc/withCropMutation";
import useBoolean from "hooks/useBoolean.js";
import usePermissions from "hooks/usePermissions";
import useRestQuery from "hooks/useRestQuery";
import { STANDARD } from "model/Subscription/constants";
import useYearFieldCrops from "modules/fields/hooks/useYearFieldCrops";

import PayGateAdBanner from "components/advertisements/banners/PayGateAdBanner";
import { Button } from "components/fl-ui";
import Dialog from "components/fl-ui/Dialog";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { styles, fieldCardGrid } from "fields/common";
import {
  FieldEditModal,
  FieldGroup,
  FieldCard,
  FieldCompact,
  FilterModal,
  WeatherAttributionFooter,
} from "fields/components";
import FilterBar from "fields/components/FilterBar";
import FieldIndexContext from "fields/components/context/FieldIndexContext";
import withCustomizedData from "fields/hoc";

const DialogControls = ({ onCancel, onConfirm }) => {
  return (
    <>
      <LeftButton color="primary" link onClick={onCancel}>
        Cancel
      </LeftButton>
      <Button color="danger" onClick={onConfirm}>
        Confirm
      </Button>
    </>
  );
};

const MemoizedFieldCard = memo(FieldCard);

const ConditionalPayGate = () => {
  const subscriptionId = useRestQuery(getCurrentSubscription).data?.subscription?.currentPlan?.id;
  return subscriptionId === STANDARD ? <PayGateAdBanner /> : null;
};

const useRenderStrategy = (fieldCount) => {
  const isStandard = fieldCount <= 250;
  const isDelayed = _.inRange(fieldCount, 251, 451);
  const isThrottled = fieldCount > 450;

  return useMemo(() => {
    const strategy = {};

    strategy.card = fieldCount > 1000 ? "delayed" : "standard";

    strategy.rainfall = "standard";
    if (isDelayed) {
      strategy.rainfall = "delayed";
    } else if (isThrottled) {
      strategy.rainfall = "throttled";
    }

    return strategy;
  }, [isStandard, isDelayed, isThrottled]);
};

const FieldViewContainer = (props) => {
  const hasWritePermissions = usePermissions().hasPermission("fields", ["write"]);

  const { createCrop, editCrop, executeFieldDelete, executeGroupCreate, rainfallHasLoaded, ...rest } = props;
  const { filteredFieldCount, sortCriteria, year } = rest;
  const { getYearCropsForField, loading: cropsLoading } = useYearFieldCrops(year);

  const [subjectFieldId, setSubjectFieldId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFieldEditModal, setShowFieldEditModal] = useState(false);

  const showLoadingSpinner = !rainfallHasLoaded && ["asc", "desc"].includes(sortCriteria);

  let fieldDeleteMessage = "";
  if (showDeleteModal) {
    const fieldToDelete = _.find(rest.fields, { id: subjectFieldId });
    fieldDeleteMessage = `By deleting the field "${fieldToDelete?.name}" you will delete all data associated
      with the field including crops and activities`;
  }

  const renderStrategy = useRenderStrategy(filteredFieldCount);
  const navigate = useNavigate();
  const context = useMemo(
    () => ({
      onAddCrop: (fieldId) => {
        createCrop(fieldId);
      },

      onEditField: (fieldId) => {
        setSubjectFieldId(fieldId);
        setShowFieldEditModal(true);
      },

      onDeleteField: (fieldId) => {
        setShowDeleteModal(true);
        setSubjectFieldId(fieldId);
      },

      onEditBoundaries: (fieldId) => {
        navigate(`/fields/${fieldId}/edit`);
      },

      renderStrategy,
      year,
    }),
    [renderStrategy, year]
  );

  const performDelete = () => {
    executeFieldDelete([subjectFieldId]);
    setShowDeleteModal(false);
    setSubjectFieldId(undefined);
  };

  return (
    <FieldIndexContext.Provider value={context}>
      <LoadingWrapper isLoading={showLoadingSpinner} loadingText={"Loading rainfall data\u2026"}>
        <FieldView
          {...rest}
          cropsLoading={cropsLoading}
          getCrops={getYearCropsForField}
          canEditGroup={hasWritePermissions}
        />
      </LoadingWrapper>

      {showFieldEditModal && (
        <FieldEditModal
          createGroup={executeGroupCreate}
          fieldId={subjectFieldId}
          onClose={() => {
            setShowFieldEditModal(false);
            setSubjectFieldId(undefined);
          }}
        />
      )}

      {showDeleteModal && (
        <Dialog
          dialogBody={fieldDeleteMessage}
          dialogControls={
            <DialogControls
              onCancel={() => {
                setShowDeleteModal(false);
                setSubjectFieldId(undefined);
              }}
              onConfirm={performDelete}
            />
          }
          dialogHeading="Are you sure?"
          onClose={() => {
            setShowDeleteModal(false);
            setSubjectFieldId(undefined);
          }}
        />
      )}
    </FieldIndexContext.Provider>
  );
};

const FieldView = (props) => {
  const {
    canEditGroup,
    cropsLoading,
    executeGroupDelete,
    executeGroupUpdate,
    fields,
    filteredFieldCount,
    getCrops,
    groupedFields,
    isGrouped,
    onFilterReset,
    onFilterUpdate,
    onSortUpdate,
    onViewTypeUpdate,
    selectedFilters,
    totalFieldCount,
    year,
  } = props;
  const [showFilterModal, { off, on }] = useBoolean();
  const userConfig = useUserConfig();

  const handleFilterSelection = ({ selectedParams }) => {
    const { viewType } = selectedParams;
    if (userConfig("fieldViewType") !== viewType) {
      userConfig("fieldViewType", viewType);
    }

    onFilterUpdate(selectedParams);
  };

  const options = [
    { key: 1, label: "Recent rainfall (most to least)", value: "desc" },
    { key: 2, label: "Recent rainfall (least to most)", value: "asc" },
    { key: 3, label: "Alphabetical by field name", value: "fieldname" },
    { key: 4, label: "Alphabetical by group name", value: "default" },
  ];

  const DetailComponent = selectedFilters.viewType === "list" ? FieldCompact : MemoizedFieldCard;

  return (
    <>
      <FilterBar
        {...props}
        filteredSize={filteredFieldCount}
        handleFilterButtonClick={on}
        handleSort={onSortUpdate}
        handleViewType={onViewTypeUpdate}
        options={options}
        selected={selectedFilters.viewType}
        sortCriteria={selectedFilters.sortCriteria}
        totalSize={totalFieldCount}
      />

      {isGrouped ? (
        <>
          {_.map(groupedFields, (groupAndFields) => {
            const { group, fields } = groupAndFields;
            const key = group && group.id ? group.id : -1;
            return (
              <FieldGroup
                canEditGroup={canEditGroup}
                fields={fields}
                group={group}
                key={key}
                onDelete={executeGroupDelete}
                onGroupUpdate={executeGroupUpdate}
              >
                {_.map(fields, (field) => (
                  <DetailComponent
                    crops={getCrops(field.id)}
                    cropsLoading={cropsLoading}
                    field={field}
                    key={field.id}
                  />
                ))}
              </FieldGroup>
            );
          })}
          <ConditionalPayGate />
          <WeatherAttributionFooter />
        </>
      ) : (
        <>
          <ThemeProvider theme={fieldCardGrid}>
            <Grid className={css(styles.fieldGroup_grid_noGroup)} fluid>
              <Row className={css(styles.fieldGroup_childContainer, styles.fieldGroup_childContainer_noGroups)}>
                {_.map(fields, (field) => (
                  <DetailComponent
                    crops={getCrops(field.id)}
                    cropsLoading={cropsLoading}
                    field={field}
                    key={field.id}
                    showGroupName
                  />
                ))}
              </Row>
            </Grid>
          </ThemeProvider>
          <ConditionalPayGate />
          <WeatherAttributionFooter />
        </>
      )}

      {/* modals/dialogs */}
      {showFilterModal && (
        <FilterModal
          onClose={off}
          onFilterReset={() => {
            off();
            onFilterReset();
          }}
          onSubmit={handleFilterSelection}
          options={options}
          selected={selectedFilters.viewType}
          selectedFilters={selectedFilters}
          sortCriteria={selectedFilters.sortCriteria}
          year={year}
        />
      )}
    </>
  );
};

export default withCropMutation(withCustomizedData(FieldViewContainer));
