import { css } from "aphrodite/no-important";
import React from "react";

import useReleaseFeature from "hooks/useReleaseFeature";

import PayGateCommonBlankSlateBanner from "components/advertisements/banners/PayGateCommonBlankSlateBanner";
import styles from "components/advertisements/styles";

const YieldDataBlankSlateBanner = () => {
  const noCCRequired = useReleaseFeature("no-cc-required-for-trial");
  const planDisclaimer = "The Machine Data feature is included in the Business plan and requires a paid subscription.";

  const props = {
    actionButtonText: noCCRequired ? "Upgrade Plan" : "Start a free trial",
    imagePath: "images/pay_gates/banners/farm.png",
    title: "Upgrade to the Business Plan to upload Machine Data files",
  };

  return (
    <PayGateCommonBlankSlateBanner {...props} size="lg">
      <p className={css(styles.secondaryText)}>
        Uploading machine data files will automatically convert as-applied, as-planted, or yield files into Activities
        so you can accurately track field work and the costs associated. {noCCRequired ? planDisclaimer : ""}
      </p>
    </PayGateCommonBlankSlateBanner>
  );
};

export default YieldDataBlankSlateBanner;
