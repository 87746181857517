import themeGet from "@styled-system/theme-get";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Icon } from "components/fl-ui/Icons";
import DesktopMenuLabel from "components/fl-ui/Layout/Navigation/DesktopMenu/DesktopMenuLabel";
import { GreyColors } from "components/fl-ui/colors";
import { BorderRadius, Mixins, Spacing, Typography } from "components/fl-ui/constants";

const DesktopMenuItemContainer = styled.li`
  border-radius: ${BorderRadius.medium};
  font-weight: ${Typography.weights.regular};
  height: ${Mixins.toRem(40)};
  margin-bottom: ${Spacing.xsmall};
  transition: background-color 100ms ease-in;
  width: 100%;

  &:has(a.active) {
    background-color: ${({ theme }) => theme.colors.lightestBlue};
  }

  a {
    align-items: center;
    color: ${GreyColors.smoke40};
    display: flex;
    gap: ${Spacing.medium};
    justify-content: flex-start;
    padding: ${Mixins.toRem(11)} 0 ${Mixins.toRem(11)} ${Mixins.toRem(14)};
    text-decoration: none;
  }
  a:hover {
    font-weight: ${Typography.weights.medium};
  }

  a > :first-child {
    display: block;
    height: 20px;
    transform: translateY(-1px);
    width: 20px;
  }

  a.active {
    color: ${themeGet("colors.blue")};
    font-weight: ${themeGet("fontWeights.bold")};
  }
`;

const DesktopMenuItem = ({ icon, label, onClick, title, to }) => {
  const cn = ({ isActive }) => (isActive ? "active" : null);

  return (
    <DesktopMenuItemContainer>
      <NavLink className={cn} onClick={onClick} to={to}>
        <Icon icon={icon} />
        <span>{title}</span>

        {label && <DesktopMenuLabel>{label}</DesktopMenuLabel>}
      </NavLink>
    </DesktopMenuItemContainer>
  );
};

DesktopMenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default DesktopMenuItem;
