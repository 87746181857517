import { css } from "aphrodite/no-important";
import { SalesPhoneLink, SupportEmailLink } from "billing/lib";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Grid, Row } from "react-styled-flexboxgrid";

import { marketingClient, wrapWithProvider } from "collection/graphql/client";
import { useUserConfig } from "collection/graphql/config";
import { getCurrentSubscription } from "collection/graphql/subscription/index";
import useRestSuspenseQuery from "hooks/useRestSuspenseQuery";
import { ONBOARDING_SEE_MORE, ONBOARDING_START_TRIAL } from "lib/metrics/events";

import { Button } from "components/fl-ui";
import { Logo } from "components/fl-ui/Icons";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import Fullscreen from "components/fl-ui/util/Fullscreen";
import CropHistoryCard from "fields/onboarding/CropHistoryCard";
import RainfallTrendChart from "fields/onboarding/RainfallTrendChart";
import SoilMapCard from "fields/onboarding/SoilMapCard";
import styles from "fields/onboarding/onboardingStyles";

const onboardingFooterHelp = (
  <p className={css(styles.footerText)}>
    Have questions? You can reach us at <SalesPhoneLink /> or <SupportEmailLink />.
  </p>
);

const OnboardingDashboardMain = ({ field, handleMore }) => {
  const { geometry, id, name } = field;
  const { isSubscribed } = useRestSuspenseQuery(getCurrentSubscription).data.subscription;
  const navigate = useNavigate();

  return (
    <>
      <Row>
        <Col xs>
          <div className={css(styles.banner)}>
            <h2 className={css(styles.bannerHeading)}>
              {`Great job adding "${name}" as your first field. Check out some of the information Bushel Farm has for this field below.`}
            </h2>
            <hr className={css(styles.bannerHr)} />
          </div>
        </Col>
      </Row>

      <Row className={css(styles.rowPadding)}>
        <Col xs>
          <RainfallTrendChart fieldId={id} title="Rainfall history" />
        </Col>
      </Row>

      <Row>
        <Col className={css(styles.colPadding)} xs={12} sm={12} md={6}>
          <SoilMapCard fieldId={id} geometry={geometry} skip={false} />
        </Col>

        <Col className={css(styles.colPadding)} xs={12} sm={12} md={6}>
          <CropHistoryCard geometry={geometry} />
        </Col>
      </Row>

      <Row>
        <Col xs>
          <div className={css(styles.banner)}>
            <h3 className={css(styles.bannerHeading)}>See what else you can do with Bushel Farm</h3>
            <div className={css(styles.buttonGroupContainer)}>
              {isSubscribed && (
                <>
                  <Button
                    className={css(styles.button)}
                    color="primary"
                    display="inline"
                    onClick={() => {
                      navigate("/fields");
                    }}
                    size="lg"
                  >
                    Go to Fields
                  </Button>
                  <Button
                    className={css(styles.button)}
                    color="primary"
                    display="inline"
                    hollow
                    onClick={() => {
                      navigate("/billing");
                    }}
                    size="lg"
                  >
                    Manage Subscription
                  </Button>
                </>
              )}
              {!isSubscribed && (
                <>
                  <Button
                    className={css(styles.button)}
                    color="primary"
                    display="inline"
                    onClick={handleMore}
                    size="lg"
                  >
                    See more
                  </Button>
                  <Button
                    className={css(styles.button)}
                    color="primary"
                    display="inline"
                    hollow
                    onClick={() => {
                      ONBOARDING_START_TRIAL.track({});
                      navigate("/billing");
                    }}
                    size="lg"
                  >
                    Start a free trial now
                  </Button>
                </>
              )}
            </div>
            {onboardingFooterHelp}
          </div>
        </Col>
      </Row>
    </>
  );
};

const OnboardingDashboardMore = () => {
  const navigate = useNavigate();
  return (
    <>
      <Row>
        <Col className={css(styles.centeredContainer)} xs>
          <img alt="Record keeping" height="434" src="images/onboarding/record-keeping.png" width="703" />
        </Col>
      </Row>

      <Row>
        <Col xs>
          <div className={css(styles.banner)}>
            <h2 className={css(styles.bannerHeading)}>Stay organized with digital records</h2>
            <p className={css(styles.onboardingText)}>
              Easily log all your field work and costs in one place to streamline reporting and analyze the profit and
              loss of your business
            </p>

            <hr className={css(styles.bannerHr)} />
          </div>
        </Col>
      </Row>

      <Row className={css(styles.rowPadding, styles.rowColumnReverseMobile)}>
        <Col xs={12} sm={12} md={6}>
          <div className={css(styles.sideBanner, styles.columnMobile)}>
            <h2 className={css(styles.bannerHeading)}>Be confident in your marketing decisions</h2>
            <p className={css(styles.onboardingText, styles.sideBannerText)}>
              Know the value of your unsold bushels as prices move and easily track crop sales, hedges, and overall
              marketing position against your goals and breakeven.
            </p>
          </div>
        </Col>

        <Col className={css(styles.centeredContainer)} xs={12} sm={12} md={6}>
          <img src="images/onboarding/onboarding-preview-marketing-card.png" />
        </Col>
      </Row>

      <Row className={css(styles.rowPadding, styles.columnMobile)}>
        <Col className={css(styles.centeredContainer)} xs={12} sm={12} md={6}>
          <img
            alt="Stay organized with activities"
            height="510"
            src="images/pay_gates/banners/activities.svg"
            width="343"
          />
        </Col>

        <Col xs={12} sm={12} md={6}>
          <div className={css(styles.sideBanner, styles.columnMobile)}>
            <h2 className={css(styles.bannerHeading)}>Stay Organized with Digital Records</h2>
            <p className={css(styles.onboardingText, styles.sideBannerText)}>
              Easily log and review all your field work in one place to keep your records organized, secure, and
              accessible from any desktop or mobile device.
            </p>
          </div>
        </Col>
      </Row>

      <Row className={css(styles.rowPadding, styles.rowColumnReverseMobile)}>
        <Col xs={12} sm={12} md={6}>
          <div className={css(styles.sideBanner, styles.columnMobile)}>
            <h2 className={css(styles.bannerHeading)}>{`Discover problems before it's too late`}</h2>
            <p className={css(styles.onboardingText, styles.sideBannerText)}>
              Get in-season satellite imagery with NDVI, infrared, and true color layers to see crop conditions
              throughout the season
            </p>
          </div>
        </Col>

        <Col className={css(styles.centeredContainer)} xs={12} sm={12} md={6}>
          <img src="images/pay_gates/banners/imagery.svg" />
        </Col>
      </Row>

      <hr className={css(styles.bannerHr)} />

      <Row>
        <Col xs>
          <div className={css(styles.banner)}>
            <h3 className={css(styles.bannerHeading)}>Get started with Bushel Farm today</h3>
            <div className={css(styles.buttonGroupContainer)}>
              <Button
                className={css(styles.button)}
                color="primary"
                display="inline"
                onClick={() => {
                  ONBOARDING_START_TRIAL.track({});
                  navigate("/billing");
                }}
                size="lg"
              >
                Start a free trial now
              </Button>
            </div>

            {onboardingFooterHelp}
          </div>
        </Col>
      </Row>
    </>
  );
};

const OnboardingDashboard = ({ field }) => {
  const [showMore, setShowMore] = useState(false);
  const containerRef = useRef();
  const userConfig = useUserConfig();

  useEffect(() => {
    if (!userConfig("didOnboarding")) {
      userConfig("didOnboarding", true);
    }
  }, []);

  const handleMore = () => {
    ONBOARDING_SEE_MORE.track({});
    setShowMore(true);
    containerRef?.current?.scrollTo(0, 0);
  };

  return (
    <Fullscreen className={css(styles.fullscreen)} innerRef={containerRef}>
      <Grid className={css(styles.grid)}>
        <Row className={css(styles.headerContainer)}>
          <Col xs>
            <div className={css(styles.headerBorder)}>
              <Logo size="sm" />
            </div>
          </Col>
        </Row>

        <Suspense fallback={() => <LoadingWrapper isLoading />}>
          {showMore ? <OnboardingDashboardMore /> : <OnboardingDashboardMain field={field} handleMore={handleMore} />}
        </Suspense>
      </Grid>
    </Fullscreen>
  );
};

export default wrapWithProvider(OnboardingDashboard, marketingClient);
