import { useQuery } from "@apollo/client";
import { sortBy } from "lodash";
import { CARGILL_ELEVATE, MARKETING_PAGE_POLL_INTERVAL, SUMMARY } from "marketing/constants";
import MarketingPageContainer from "marketing/containers/MarketingPageContainer";
import PropTypes from "prop-types";
import React, { useLayoutEffect, useMemo } from "react";

import { getAdvisorRecommendationCommoditiesByYear, getAdvisorRecommendations } from "collection/graphql/advisor";
import { GET_DASHBOARD_MARKETED_CROPS } from "collection/graphql/marketing";
import { MarketingPageContextProvider } from "context/MarketingPageContext";
import useAdvisorQuery from "hooks/useAdvisorQuery";
import useCommodities from "hooks/useCommodities";
import useCurrentCropYear from "hooks/useCurrentCropYear";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";
import { MARKETING_TOTAL_FARM_VIEW } from "lib/metrics/events";

import MarketingBlankStatePage from "components/advertisements/pages/MarketingBlankStatePage";
import Container from "components/fl-ui/Layout/Container";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import { RestrictedAccessWithHeader } from "components/fl-ui/RestrictedAccessWithHeader";

const Dashboard = ({ view }) => {
  const [cropYear] = useCurrentCropYear();
  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "grain_marketing",
    permissionName: "marketing",
  });

  const { getCommodityById } = useCommodities();

  const { data: marketedCropsData, loading: marketedCropsLoading } = useQuery(GET_DASHBOARD_MARKETED_CROPS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    errorPolicy: "ignore",
    pollInterval: MARKETING_PAGE_POLL_INTERVAL,
    skip: !cropYear || showRestricted,
    variables: { year: cropYear },
  });

  const advisorRecommendationsResponse = useAdvisorQuery(getAdvisorRecommendations, {
    skip: view === CARGILL_ELEVATE || !showContent || showRestricted,
  });

  const advisorRecommendationsLoading = advisorRecommendationsResponse?.loading || false;
  const advisorRecommendations = advisorRecommendationsResponse?.data?.getAdvisorRecommendations || [];

  const advisorCommoditiesResponse = useAdvisorQuery(getAdvisorRecommendationCommoditiesByYear, {
    skip: !cropYear || !showContent || showRestricted,
    variables: {
      year: cropYear,
    },
  });

  const advisorCommoditiesLoading = advisorCommoditiesResponse?.loading || false;
  const advisorCommodities = advisorCommoditiesResponse?.data?.getAdvisorRecommendationCommoditiesByYear || [];

  const marketedCrops = useMemo(() => {
    if (!marketedCropsLoading && !advisorRecommendationsLoading && marketedCropsData?.marketedCrops) {
      const { marketedCrops, partialMarketedCrops } = marketedCropsData;
      const combinedMarketedCrops = marketedCrops.concat(partialMarketedCrops);

      const recommendationsForCurrentYear = advisorRecommendations.filter(
        (recommendation) => recommendation.cropYear === cropYear
      );

      const recommendationsWithAssociatedAdvisorCommodities = recommendationsForCurrentYear.filter((recommendation) =>
        advisorCommodities.find(
          (advisorCommodity) => advisorCommodity.commodityId === recommendation.commodity.commodityId
        )
      );

      const orphanedRecommendations = recommendationsWithAssociatedAdvisorCommodities.filter((recommendation) => {
        const associatedMarketedCrop = combinedMarketedCrops.find(
          (marketedCrop) =>
            marketedCrop.commodity.id === recommendation.commodity.commodityId &&
            marketedCrop.year === recommendation.cropYear
        );
        return !associatedMarketedCrop;
      });

      const orphanedRecommendationsAsMarketedCrops = orphanedRecommendations.map((orphanedRecommendation) => {
        const commodity =
          getCommodityById(orphanedRecommendation.commodity.commodityId) ?? orphanedRecommendation.commodity;
        return {
          __typename: "RecommendedMarketedCrop",
          commodity,
          name: commodity.name + " " + orphanedRecommendation.cropYear,
          year: orphanedRecommendation.cropYear,
        };
      });

      return sortBy(combinedMarketedCrops.concat(orphanedRecommendationsAsMarketedCrops), "name");
    }

    return [];
  }, [advisorCommodities, advisorRecommendations, marketedCropsData, marketedCropsLoading, cropYear]);

  useLayoutEffect(() => {
    MARKETING_TOTAL_FARM_VIEW.track();
  }, []);

  return (
    <div>
      <LoadingWrapper isLoading={loadingAccess}>
        {showPaygate && (
          <Container>
            <Header title="Marketing" />
            <MarketingBlankStatePage />
          </Container>
        )}
        {showRestricted && <RestrictedAccessWithHeader title="Marketing" />}
        {showContent && (
          <MarketingPageContextProvider
            advisorCommodities={advisorCommodities}
            advisorRecommendations={advisorRecommendations}
          >
            <MarketingPageContainer
              loading={marketedCropsLoading || advisorRecommendationsLoading || advisorCommoditiesLoading}
              marketedCrops={marketedCrops}
              view={view}
            />
          </MarketingPageContextProvider>
        )}
      </LoadingWrapper>
    </div>
  );
};

Dashboard.propTypes = {
  view: PropTypes.oneOf([CARGILL_ELEVATE, SUMMARY]).isRequired,
};

export default Dashboard;
