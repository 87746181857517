import withPersonList from "activity/hoc/withPersonList";
import BuyerModal from "loads/components/BuyerModal";
import InventoryTransactionModal from "loads/components/InventoryTransactionModal";
import { compact, sortBy } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import BinsView from "resources/bin";

// Legacy
import Bins from "collection/bins";
import Buyers from "collection/buyers";
import { useAuth } from "collection/graphql/auth";
import { marketingClient, wrapWithProvider } from "collection/graphql/client";
import InventoryTransactions from "collection/inventory_transactions";
// HOC
import withCropMutation from "hoc/withCropMutation";
import useCommodities from "hooks/useCommodities";
import useUnits from "hooks/useUnits";
import App from "layout/app";
import useFieldCrops from "modules/fields/hooks/useFieldCrops";

const getCropName = ({ commodity, cropYear, field }) => `${field.name} (${cropYear} ${commodity?.name})`;

const confirm = "Continue";
const toOption = (option) => ({
  description: compact([option.city, option.state]).join(", "),
  name: option.name,
  value: option.inventory_node_id,
});

const getCategorizedOptions = (crops) => ({
  buyers: Buyers.toJSON().map(toOption),
  crops,
  storage: Bins.toJSON().map(toOption),
});

const useCommodityCrops = (commodityId) => {
  const { getCropsForCommodity, loading } = useFieldCrops();
  const crops = getCropsForCommodity(commodityId)?.map((crop) => ({
    inventory_node_id: crop.inventoryNodeId,
    name: getCropName(crop),
    year: crop.cropYear,
  }));

  return { crops: sortBy(crops, ({ year }) => -year).map(toOption), loading };
};

const InventoryTransaction = ({ createCrop, load, onClose, personList }) => {
  const { getCommodityById } = useCommodities();
  const { currentUser } = useAuth();
  const binsRef = useRef();
  const defaults = { date: load.date || moment().format("YYYY-MM-DD"), person_id: load.person_id || currentUser.id };
  const [data, setData] = useState({ ...load, ...defaults });
  const { commodity_id, destination_id, id, source_id } = data;
  const { crops, loading } = useCommodityCrops(commodity_id);
  const [categorizedOptions, setCategorizedOptions] = useState(() => getCategorizedOptions(crops));
  const [subModal, setSubModal] = useState(null);
  const [error, setError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const weightUnits = useUnits().data?.weightUnits || [];
  const units = [
    { name: "bushels", value: "bu" },
    ...weightUnits.map(({ abbr, display }) => ({ name: display, value: abbr })),
  ];

  const hasBuyer = !!Buyers.where({ inventory_node_id: destination_id }).length;

  const updateData = (newData) => {
    setData((data) => ({ ...data, ...newData }));
    setError("");
  };
  const updateCategorizedOptions = (category, savedData, attribute = "destination_id") => {
    setCategorizedOptions((options) => ({ ...options, [category]: [...options[category], toOption(savedData)] }));
    updateData({ [attribute]: savedData.inventory_node_id });
    setSubModal(null);
  };

  useEffect(() => {
    if (commodity_id && !loading) {
      setCategorizedOptions((options) => ({ ...options, crops }));
    }
  }, [commodity_id, loading]);

  useEffect(() => {
    if (subModal === "storage") {
      // Mount a backbone / mustache view and set its event listeners
      const view = new BinsView({ el: binsRef.current }).render();
      view.on("cancel", () => setSubModal(null));
      view.on("finish", (savedModel) => {
        const savedData = savedModel?.toJSON();

        if (savedData) {
          updateCategorizedOptions("storage", savedData);
        }
      });
    } else if (subModal === "crops") {
      const onUpdate = (crop) => {
        const result = { inventory_node_id: crop.inventoryNodeId, name: getCropName(crop) };
        updateCategorizedOptions("crops", result, "source_id");
      };

      createCrop(null, { commodityId: commodity_id }, { onUpdate });
    }
  }, [subModal]);

  const saveTransaction = () => {
    const model = id ? InventoryTransactions.get(id) : new InventoryTransactions.model();
    setIsSaving(true);

    model.save(data).then(
      () => {
        InventoryTransactions.add(model, { merge: true });
        onClose();
      },
      () => {
        setIsSaving(false);
        App.notify("An error occurred");
      }
    );
  };

  const deleteTransaction = () => {
    App.confirm({ confirm, message: "Are you sure you want to delete this transaction" }).then(() => {
      setIsSaving(true);
      InventoryTransactions.get(id)
        .destroy({ wait: true })
        .then(() => {
          onClose();
        });
    });
  };

  const onSave = () => {
    if (!destination_id && !source_id) {
      setError("Either a destination or a source is required.");
      return null;
    } else if (destination_id === source_id) {
      setError("Destination and source cannot be the same.");
      return null;
    }

    if (id && destination_id && load.commodity_id !== commodity_id) {
      const { buyers, storage } = categorizedOptions;
      const nodes = [...buyers, ...storage];
      const commodityName = getCommodityById(commodity_id).name;
      const prevCommodityName = getCommodityById(load.commodity_id).name;
      const destinationName = nodes.find(({ value }) => value === destination_id)?.name;
      const message = `Moving ${commodityName} into ${destinationName} will remove the ${load.amount}
        ${load.amount_unit} of ${prevCommodityName} already there. Are you sure you want to add ${commodityName}?`;

      App.confirm({ confirm, message }).then(() => {
        saveTransaction();
      });
    } else {
      saveTransaction();
    }
  };

  return (
    <>
      <InventoryTransactionModal
        categorizedOptions={categorizedOptions}
        data={data}
        error={error}
        hasBuyer={hasBuyer}
        hideModal={subModal}
        isSaving={isSaving}
        onClose={onClose}
        onDelete={deleteTransaction}
        onSave={onSave}
        personList={personList}
        setSubModal={setSubModal}
        units={units}
        updateData={updateData}
      />
      {subModal === "storage" && <div ref={binsRef} />}
      {subModal === "buyers" && <BuyerModal onClose={() => setSubModal(null)} onSave={updateCategorizedOptions} />}
    </>
  );
};

export default wrapWithProvider(withCropMutation(withPersonList(InventoryTransaction)), marketingClient);
