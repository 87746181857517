import { Dialog } from "@capacitor/dialog";
import { browserOpenUrl, isFullWeb, getPlatform, isCapacitor5 } from "mobile/mobileManager";

import {
  trackMobileForcedAppUpdatePresentation,
  trackMobileForcedAppUpdateResolution,
} from "lib/metrics/events/trackEvents";

const appStoreUrl = "https://apps.apple.com/us/app/bushel-farm/id644043593";
const playStoreUrl = "https://play.google.com/store/apps/details?id=io.trigger.forge633ed212c46d11e185cd12313d1adcbe";

export const showAppDeprecated = async (title = "App Update Available") => {
  trackMobileForcedAppUpdatePresentation("DEPRECATED");

  if (await isFullWeb()) {
    showAppDeprecatedDialog(title);
  } else if (isCapacitor5) {
    showAppDeprecatedAlert(title);
  } else {
    showAppDeprecatedModal(title);
  }
};

// "Full Web"
const showAppDeprecatedDialog = async (title) => {
  const confirmResult = await Dialog.confirm({
    title,
    message: "A new version of Bushel Farm is available",
    okButtonTitle: "Update",
    cancelButtonTitle: "Dismiss",
  });

  if (confirmResult.value) {
    openStore();
  } else {
    handleDismiss();
  }
};

// "Mega-Plugin"
const showAppDeprecatedAlert = async (title) => {
  const confirmResult = confirm(title);
  if (confirmResult) {
    openStore();
  } else {
    handleDismiss();
  }
};

// "Cap 2"
const showAppDeprecatedModal = async (title) => {
  const confirmResult = await window.Capacitor.nativePromise("Modals", "confirm", {
    title,
    message: "A new version of Bushel Farm is available",
    okButtonTitle: "Update",
    cancelButtonTitle: "Dismiss",
  });

  if (confirmResult.value) {
    openStore();
  } else {
    handleDismiss();
  }
};

export const openStore = async () => {
  trackMobileForcedAppUpdateResolution("STORE");
  if (getPlatform() === "ios") {
    await browserOpenUrl(appStoreUrl);
  } else {
    await browserOpenUrl(playStoreUrl);
  }
};

const handleDismiss = () => {
  trackMobileForcedAppUpdateResolution("DISMISS");
};
