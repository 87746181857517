import { DatePicker } from "@farmlogs/fl-ui";
import useCashContractForm from "contracts/form/CashContractForm/useCashContractForm";
import withCashContractFormContext from "contracts/form/CashContractForm/withCashContractFormContext";
import ContractBuyerDropdown from "contracts/form/components/ContractBuyerDropdown";
import ContractCommodityDropdown from "contracts/form/components/ContractCommodityDropdown";
import ContractMarketedCropDropdown from "contracts/form/components/ContractMarketedCropDropdown";
import ContractStatusDropdown from "contracts/form/components/ContractStatusDropdown";
import ContractSubtypeDropdown from "contracts/form/components/ContractSubtypeDropdown";
import CropYearDropdown from "contracts/form/components/CropYearDropdown";
import useContractPermissions from "contracts/hooks/useContractPermissions";
import PropTypes from "prop-types";
import React from "react";

import useCreateCashContract from "collection/graphql/contracts/hooks/useCreateCashContract";
import useEditCashContract from "collection/graphql/contracts/hooks/useEditCashContract";
import getPricelines from "collection/graphql/contracts/queries/getPricelines";
import { trackCashContractSave } from "lib/metrics/events/trackEvents";

import { ControlledFormGroup, Form, FormGroup, Input, NumericInput } from "components/fl-ui/Form";
import ContractUnit from "components/units/ContractUnit";

const ContractFieldGroup = ({ editableIfPartner = false, render, ...props }) => {
  const {
    formState: { isLoading },
    isEdit,
    isPartner,
  } = useCashContractForm();
  const isEditable = !isEdit || !isPartner || editableIfPartner;
  const disabled = isLoading || !isEditable;

  return <ControlledFormGroup {...props} render={(props) => render({ ...props, disabled })} />;
};

const CashContractForm = withCashContractFormContext(({ formId, layout = "flow-vertical", onSave }) => {
  const form = useCashContractForm();
  const { commodities, contract, isEdit } = form;
  const createContract = useCreateCashContract();
  const editContract = useEditCashContract({
    refetchQueries: [
      {
        query: getPricelines,
        variables: { id: form.getValues("id") },
      },
    ],
  });
  const commodityId = form.watch("commodityId");
  const cropYear = form.watch("cropYear");
  const isCommodityExchangeTraded = commodities.filter((commodity) => commodity.id === commodityId)[0]
    ?.isExchangeTraded;

  const { requiresMarketingUpgrade } = useContractPermissions();

  const handleSubmit = async () => {
    const formValues = form.getValues();
    const action = formValues.id ? editContract : createContract;
    const result = await action(formValues);

    onSave(result.data?.createCashContract?.contract);
    trackSave(formValues);
  };

  const trackSave = (formValues) => {
    // need undefined and null to be equal for this comparison
    const marketedCropModified = contract?.marketedCrop?.id != formValues.marketedCropId;
    const sourceCTA = (() => {
      if (isEdit) {
        switch (layout) {
          case "flow-vertical":
            return "edit_contract";
          case "columnar":
            return "kebab_menu";
          default:
            return "unknown_layout_" + layout;
        }
      } else {
        return "add_contract";
      }
    })();

    trackCashContractSave(marketedCropModified, sourceCTA);
  };

  return (
    <Form id={formId} layout={layout} onSubmit={handleSubmit} preventDefault>
      <ContractFieldGroup
        name="buyer"
        render={({ disabled, field }) => (
          <ContractBuyerDropdown {...field} disabled={disabled} placeholder="Enter buyer name" />
        )}
      />

      <ContractFieldGroup
        maxLength={50}
        name="contractNumber"
        render={({ disabled, field }) => (
          <Input {...field} controlled disabled={disabled} placeholder="Enter contract #" />
        )}
      />

      <ContractFieldGroup
        name="commodityId"
        render={({ disabled, field }) => (
          <ContractCommodityDropdown {...field} disabled={disabled || isEdit} placeholder="Select a commodity" />
        )}
      />

      <ContractFieldGroup
        editableIfPartner
        label="Crop Year"
        name="cropYear"
        render={({ disabled, field }) => (
          <CropYearDropdown {...field} disabled={disabled} placeholder="Select crop year" />
        )}
      />

      <ContractFieldGroup
        editableIfPartner
        label="Marketing Crop"
        name="marketedCropId"
        render={({ disabled, field }) => (
          <ContractMarketedCropDropdown
            {...field}
            disabled={disabled || !commodityId || !cropYear || requiresMarketingUpgrade()}
            placeholder="Assign to a marketing crop"
          />
        )}
        tip={
          requiresMarketingUpgrade()
            ? "Upgrade your subscription to organize your contracts into Marketing Crops"
            : "Create new Marketing Crops in the Marketing feature"
        }
      />

      <ContractFieldGroup
        name="contractDate"
        render={({ disabled, field }) => <DatePicker {...field} disabled={disabled} />}
      />

      <ContractFieldGroup
        name="contractType"
        render={({ disabled, field }) => (
          <ContractSubtypeDropdown
            {...field}
            contractType="CashContract"
            disabled={disabled || !commodityId}
            placeholder="Select contract type"
            isOptionDisabled={(option) =>
              commodityId &&
              !isCommodityExchangeTraded &&
              option.value !== "CASH" &&
              option.value !== "OTHER" &&
              option.value !== "SPOT"
            }
          />
        )}
      />

      <ContractFieldGroup
        name="contractStatus"
        render={({ disabled, field }) => (
          <ContractStatusDropdown {...field} disabled={disabled} placeholder="Select status" />
        )}
      />

      {isEdit && (
        <FormGroup label="Pricing">
          <NumericInput disabled min={1} type="currency" value={contract?.netPrice} />
        </FormGroup>
      )}

      <ContractFieldGroup
        name="quantity"
        render={({ disabled, field }) => (
          <NumericInput {...field} disabled={disabled} min={1} suffix={<ContractUnit />} type="float" />
        )}
      />

      <ContractFieldGroup
        name="quantityDelivered"
        render={({ disabled, field }) => {
          const quantity = form.watch("quantity");

          return (
            <NumericInput
              {...field}
              disabled={disabled || !(quantity > 0)}
              min={0}
              suffix={<ContractUnit />}
              type="float"
            />
          );
        }}
      />

      <ContractFieldGroup
        name="quantityCanceled"
        render={({ disabled, field }) => {
          const quantity = form.watch("quantity");

          return (
            <NumericInput
              {...field}
              disabled={disabled || !(quantity > 0)}
              min={0}
              suffix={<ContractUnit />}
              type="float"
            />
          );
        }}
      />

      {isEdit && (
        <FormGroup label="Quantity Priced">
          <NumericInput disabled min={1} type="float" value={contract?.quantityPriced} />
        </FormGroup>
      )}
    </Form>
  );
});

CashContractForm.propTypes = {
  contractId: PropTypes.number,
  formId: PropTypes.string.isRequired,
  layout: PropTypes.oneOf(["columnar", "flow-horizontal", "flow-vertical"]),
  onSave: PropTypes.func.isRequired,
};

export default CashContractForm;
