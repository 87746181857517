import useAnalyticsSetup from "app/hooks/useAnalyticsSetup";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import useMobileListeners from "app/hooks/useMobileListeners";
import useSentrySetup from "app/hooks/useSentrySetup";
import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import { useAuth } from "collection/graphql/auth";
import GlobalContextProvider from "context/GlobalContextProvider";
import useCurrentEnterprise from "hooks/useCurrentEnterprise";
import EventChannel from "lib/events";
import NotificationPopoverContainer from "modules/notifications/containers/NotificationPopoverContainer";

import PrimaryBanner from "components/banners/PrimaryBanner";
import Navigation from "components/fl-ui/Layout/Navigation";
import ContentContainer from "components/fl-ui/Layout/Navigation/ContentContainer";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import Toast from "components/fl-ui/Toast";
import LoggedOutModal from "components/modals/LoggedOutModal";

const App = ({ children }) => {
  const { hasInvalidated, isLoggedIn, isLoggingOut, loading: authIsLoading } = useAuth();
  const { currentEnterprise, loading: enterpriseLoading } = useCurrentEnterprise();
  const lastEnterpriseId = useRef();
  const hasEnterpriseChanged = !enterpriseLoading && lastEnterpriseId.current !== currentEnterprise?.id;

  if (hasEnterpriseChanged) {
    lastEnterpriseId.current = currentEnterprise?.id;
  }

  const isSyncing = [authIsLoading, isLoggingOut, enterpriseLoading && !lastEnterpriseId.current].some((x) => x);
  const loadingMessage = isLoggingOut ? `Logging out of Bushel Farm\u2026` : `Fetching data. Please wait\u2026`;

  useDocumentTitle();
  useAnalyticsSetup();
  useSentrySetup();
  useMobileListeners();

  useEffect(() => {
    if (!isSyncing) {
      EventChannel.getChannel("app").fire("ready");
    }
  }, [isSyncing]);

  return (
    <>
      <PrimaryBanner />
      <div id="appContent">
        <Navigation />
        <ContentContainer>{isSyncing ? <LoadingWrapper loadingText={loadingMessage} /> : children}</ContentContainer>
        <Toast />
        {isLoggedIn && <NotificationPopoverContainer />}
        {hasInvalidated && <LoggedOutModal />}
      </div>
    </>
  );
};

App.propTypes = {
  children: PropTypes.node,
};

export default ({ children }) => (
  <GlobalContextProvider>
    <App>{children}</App>
  </GlobalContextProvider>
);
