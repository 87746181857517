import _ from "lodash";
import React from "react";
import { json, redirect } from "react-router-dom";
import DashboardPage from "reports/routes/Dashboard";
import ReportPage from "reports/routes/Report";

import { marketingClient } from "collection/graphql/client";
import { getReportDefinitions } from "collection/graphql/reports";

export default [
  {
    path: "/reports",
    element: <DashboardPage />,
  },

  {
    loader: async ({ params: { reportId } }) => {
      const {
        data: { definitions },
      } = await marketingClient.query({ query: getReportDefinitions });
      const reportDefinitions = _.reject(definitions, "beta");
      const reportDefinition = _.find(reportDefinitions, { id: reportId });

      if (!reportDefinition) {
        return redirect("/reports");
      }

      return json({ reportDefinition });
    },
    path: "/reports/:reportId",
    element: <ReportPage />,
  },
];
