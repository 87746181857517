import { useSuspenseQuery } from "@apollo/client";
import { css, StyleSheet } from "aphrodite/no-important";
import useDocumentTitle from "app/hooks/useDocumentTitle";
import cx from "classnames";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-styled-flexboxgrid";
import { ReportError } from "reports/components/ReportError";
import useReportRowViewModels from "reports/hooks/useReportRowViewModels";

import { getReportDefinitions } from "collection/graphql/reports";
import { useFeatureAndPermissionAccess } from "hooks/useFeatureAndPermissionAccess";

import ReportsBlankStatePage from "components/advertisements/pages/ReportsBlankStatePage";
import { Icon } from "components/fl-ui/Icons";
import Container from "components/fl-ui/Layout/Container";
import Content from "components/fl-ui/Layout/Content";
import ContentMain from "components/fl-ui/Layout/ContentMain";
import Header from "components/fl-ui/Layout/Header";
import LoadingWrapper from "components/fl-ui/LoadingWrapper";
import RestrictedAccessBlankslate from "components/fl-ui/RestrictedAccessBlankslate";
import Tooltip from "components/fl-ui/Tooltip";
import { Spacing, Typography } from "components/fl-ui/constants";

const styles = StyleSheet.create({
  reportRowDisabled: {
    padding: "16px 0",
    opacity: 0.5,
  },
  reportRowEnabled: {
    padding: "16px 0",
    opacity: 1,
  },
  reportDescription: {
    fontSize: "16px",
    lineHeight: 1.375,
    marginBottom: 0,
  },
  divider: {
    margin: 0,
  },
  descriptionEmphasis: {
    display: "block",
    fontStyle: "italic",
    marginTop: "1em",
  },
  lockIcon: {
    color: "black",
    width: Spacing.medium,
    height: Spacing.medium,
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
  },
  reportName: {
    fontSize: Typography.sizes.md,
    fontWeight: Typography.weights.bold,
  },
});

const Dashboard = () => {
  useDocumentTitle("Reports");
  const {
    data: { definitions },
    error,
  } = useSuspenseQuery(getReportDefinitions);
  /** @type {ReportDefinition[]} */
  const reportDefinitions = _.filter(definitions, { beta: false });
  const reportRowViewModels = useReportRowViewModels(reportDefinitions);

  // lite and above users should see this screen. Standard users should still see the paygate
  // monthly_rainfall_summary_report is the lite report feature that essentials and business should also have
  const { loadingAccess, showPaygate, showRestricted, showContent } = useFeatureAndPermissionAccess({
    featureName: "monthly_rainfall_summary_report",
    permissionName: "reports",
  });

  return (
    <Container>
      <LoadingWrapper isLoading={loadingAccess}>
        <Header title="Reports" />
        {showPaygate && <ReportsBlankStatePage />}
        {showRestricted && <RestrictedAccessBlankslate />}
        {showContent && (
          <Content>
            <ContentMain fullWidth>
              {reportRowViewModels.map((viewModel) => (
                <span key={viewModel.reportId}>
                  <Row
                    className={cx(
                      css(viewModel.isDisabled ? styles.reportRowDisabled : styles.reportRowEnabled),
                      "report-details-row"
                    )}
                  >
                    <Col xs={12} md={3} className={css(styles.flexRow)}>
                      <Link
                        className={css(styles.reportName)}
                        disabled={viewModel.isDisabled}
                        to={`/reports/${viewModel.reportId}`}
                      >
                        {viewModel.title}
                      </Link>
                      {viewModel.isDisabled && (
                        <Tooltip message={viewModel.tooltipMessage}>
                          <Icon className={css(styles.lockIcon)} icon="lock" />
                        </Tooltip>
                      )}
                    </Col>
                    <Col xs={12} md={9}>
                      <p className={css(styles.reportDescription)}>{viewModel.description}</p>
                    </Col>
                  </Row>
                  <hr className={css(styles.divider)} />
                </span>
              ))}
              {error && <ReportError />}
            </ContentMain>
          </Content>
        )}
      </LoadingWrapper>
    </Container>
  );
};

export default Dashboard;
