import createReactClass from "create-react-class";
import $ from "jquery";
import PropTypes from "prop-types";
import React from "react";
import ReactDOM, { findDOMNode } from "react-dom";
import { Manager, Reference, Popper } from "react-popper";
import _ from "underscore";

const searchIcon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAC/ElEQVRYR8WX2avOQRjHHZTsrnQS9YYba6IjS8halty4cCFFshxbiRThwtJBSJYshYT/wFISJ2VfSpa4wCnZro5QItvnW8/UnPf8fjPz6633TH2a3zvPMzPfmXlmeWvatXGqKdB/b3xHGf3In8JDeAI/CrTTwjUmoAfeDTAX+uZ08pvyF3AQzhQVEhIwncZOgUabmi7juBQ+pFbIEtCFygdgudfIK76vwCN4DO9gmC2HlmUedDP/L+Sr4UKKiCwBx73O//C9G7bDr0CDJWynYbL5/COfBtdjIsoFaNqvWqW35PPhQawRs6utVaBY6ABNMBy+her7AhRwz0BrrpGPLdC538cefmy0gpPk/lK20uILOIp1pXnsIt+SOPJyt04UKE4Gm2ES+c28tnwBCixtNQWcpi605jFto3G4Z077yTfEBOiQ+WxOWsN1sR4S7B/xqbXRaxYyk5uBmVi1h5UWwvmEDmIuF3GYDd+hJ/zNquAEaL13mMMQcp1slSZt3a3WyFDy5yEBJzAuMwfthuDWSVSmE1G7QGkOXAoJ0Ml12BzGk99O7CTkdgjjGnMYSP46JGAMxjvmsNYTU4mOW1QeB4oBzapOx1bJxUBnLF+hI5yFRZX0TF2dhGpP98pd0KGWmfxzQPe69r8U66JpqkCEjuQjVl93yaYUAYtx0oWidAOmQua0RYQNwK7BdIVm0G/l0RmQgyJ1lnkqgNwoUiejPY6NMMEqrCfX1Z6bym/DPnhqv/YCXUg6RrfBzwQFGqleRK7z93z3h+CRnvUeWEClc+BsOpS0PPdzRCjgVoBuQU27S1o+xcKxIjPgfKfwoedYyav8ie+sF9EIyhXtLmnkmkkNICoi9CbsTgP7wJ2QoYHIpkDbCYqbJaDrPSoi9ipWwxNBr+I6GAnu7SebtqweMY2w10Q4ofUpIlIE+CNXlA+CEryENzbNebMTFVFUQGwZsuxBEdUQIFG5IqolIEvEDAqvVVNAuYjNFDRUW4BE6A+LdpT+ADW3hYAWgfofzbWRIfAb75YAAAAASUVORK5CYII=";
const ENTER_KEY_CODE = 13;

const getFirstOption = function (jsxResults) {
  const getProps = _.property("props");
  let props = _.map(jsxResults, getProps);

  while (!!props.length && !_.isString(props[0].children)) {
    props = _.map(props[0].children, getProps);
  }

  return _.assign({}, props[0]);
};

const getOptionAt = (results, index) => {
  for (let result in results) {
    if (_.isString(results[result].props.children)) {
      if (results[result].props.index == index) {
        return Object.assign({}, results[result].props);
      }
    } else {
      for (let nestedIndex in results[result].props.children) {
        if (results[result].props.children[nestedIndex].props.index == index) {
          return Object.assign({}, results[result].props.children[nestedIndex].props);
        }
      }
    }
  }
};

const getFocusedNode = () => {
  return $(".result.focused")[0];
};

const isSelected = (value, selectedValue) => value === selectedValue || _.includes(selectedValue, value);

const getValueText = (jsxResults, selectedValue) =>
  _(jsxResults)
    .map(function (jsx) {
      const { children, value } = jsx.props;
      if (_.isArray(children)) {
        return getValueText(children, selectedValue);
      }

      if (isSelected(value, selectedValue)) {
        return children;
      }
    })
    .compact()
    .value()
    .join(", ");

const getText = function ({ hideValue, children, value, label }) {
  if (hideValue && (value != null ? value.length : undefined)) {
    return _.pluralize(label, value.length, true);
  }

  return getValueText(children, value);
};

// Compile new "results" element from filter
// * applies filter to results
// * binds click handler to new results
const getResults = function (props, filterText, onClick, onMouseEnter, selectedValue, counter) {
  getResults.counter = counter;
  const jsxOptions = props.children;
  const filterExp = new RegExp(_.escapeRegExp(filterText), "ig");

  if (!jsxOptions.length) {
    return [];
  }

  return jsxOptions.reduce(function (results, jsxEl) {
    const { children, value } = jsxEl.props;

    // Check for top-level content
    if (_.isString(children)) {
      if (children.match(filterExp)) {
        const newJsx = React.cloneElement(jsxEl, {
          onClick,
          onMouseEnter,
          selected: isSelected(value, selectedValue),
          index: getResults.counter++,
        });
        return results.concat(newJsx);
      }
    } else {
      // Nested content filtering (groups/tabs)
      const nestedChildren = getResults(jsxEl.props, filterText, onClick, onMouseEnter, selectedValue, getResults.counter);
      // Check nested length
      if (nestedChildren.length) {
        return results.concat(React.cloneElement(jsxEl, {}, nestedChildren));
      }
    }

    return results;
  }, []);
};

getResults.counter = 0;

const Group = ({ label, children }) => (
  <div>
    <div className="result-header">{label}</div>
    {children}
  </div>
);

Group.displayName = "ChooserGroup";

const Option = ({ children, icon, onClick, secondary, selected, title, unit_type, value, focused }) => (
  <div
    className={`result \
  ${selected ? " selected" : ""} \
  ${secondary ? " secondary" : ""} \
  ${focused ? " focused" : ""}`}
    onClick={function () {
      return onClick({ selected: !selected, unit_type, value });
    }}
  >
    {icon ? <img src={icon} className="icon" /> : undefined}
    <div className="title">{children}</div>
    <div className="sub">{title}</div>
  </div>
);

Option.displayName = "ChooserOption";

export default createReactClass({
  displayName: "Chooser",

  propTypes: {
    selector: PropTypes.any,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    hideValue: PropTypes.bool,
    icons: PropTypes.bool,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    onAdd: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  },

  statics: {
    Option,
    Group,
  },

  getDefaultProps() {
    return {
      defaultOpen: false,
      children: [], // chooser option components
      className: "btn btn-transparent",
      disabled: false,
      hideValue: false, // show value text or shorthand label
      icons: false, // show/hide icons
      label: "items", // what this chooser contains
      multiple: false,
      onAdd: null, // send back the new items data
      onChange: null, // send back the updated value
      placeholder: "Choose an item...",
      displayValue: true,
      readOnly: false,
      value: null,
    };
  }, // value of

  getInitialState() {
    return {
      filterText: "",
      style: { position: "relative", display: "inline-block" }, // calculated position for dropdown
      index: -1,
      results: getResults(this.props, "", this._handleClick, this._handleMouseEnter, this.props.value, 0), // the options structure post-filter
      text: getText(this.props),
    };
  }, // text to display when values are selected

  componentDidMount() {
    if (this.props.defaultOpen) this._toggleOptions();
  },

  UNSAFE_componentWillReceiveProps(newProps) {
    return this.setState({
      results: getResults(
        newProps,
        this.state.filterText,
        this._handleClick,
        this._handleMouseEnter,
        newProps.value,
        0
      ),
      text: getText(newProps),
    });
  },

  componentDidUpdate() {
    if (this.state.show) {
      _.defer(() => this.filterTextInput.focus());
      document.body.addEventListener("click", this._handleBodyClick);
      return;
    }

    document.body.removeEventListener("click", this._handleBodyClick);
  },

  componentWillUnmount() {
    document.body.removeEventListener("click", this._handleBodyClick);
  },

  _canChoose() {
    return !(this.props.disabled || this.props.readOnly);
  },

  _handleBodyClick({ target }) {
    // eslint-disable-next-line react/no-find-dom-node
    if (!(findDOMNode(this).contains(target) || this.menu.contains(target))) {
      return this.setState({ show: false });
    }
  },

  _toggleOptions() {
    const { filterText, results } = this.getInitialState();

    return this.setState({
      index: -1,
      show: !this.state.show,
      filterText,
      results,
    });
  },

  _setFilter(e) {
    const { value } = e.target;

    return this.setState({
      filterText: value,
      results: getResults(this.props, value, this._handleClick, this._handleMouseEnter, this.props.value, 0),
    });
  },

  _clearFilter() {
    const { filterText, results } = this.getInitialState();
    return this.setState({ filterText, results });
  },

  _handleClick(e) {
    if (!this.props.multiple) {
      // TODO update logic to handle "multiple" values in an array
      if (!e.selected) {
        e.value = null;
      }
    } else {
      e.value = e.selected ? this.props.value.concat(e.value) : _.without(this.props.value, e.value);
    }

    this.props.onChange(_.assign({}, this.props, e));
    // toggle dropdown on select
    if (!this.props.multiple) {
      return this._toggleOptions();
    }
  },

  _handleEnter({ keyCode, nativeEvent }) {
    if (keyCode !== ENTER_KEY_CODE) {
      return;
    }
    let props;
    let first;

    if (this.state.index == -1) {
      first = getFirstOption(this.state.results);
      props = getOptionAt(this.state.results, 0);
      if (props) {
        props.selected = !props.selected;
        return this._handleClick(props);
      }
    } else {
      first = getFirstOption(this.state.results);
      props = getOptionAt(this.state.results, this.state.index);
      props.selected = !props.selected;
      return this._handleClick(props);
    }

    return this._handleAdd(nativeEvent);
  },

  _handleAdd(e) {
    e.preventDefault();
    if (!this.props.onAdd) {
      return;
    }

    this.props.onAdd(this.state.filterText);
    if (!this.props.multiple) {
      return this._toggleOptions();
    }
  },

  _handleKeyDown(event) {
    switch (event.keyCode) {
      // Up arrow
      case 38:
        return this._upPressed(event);

      // Down arrow
      case 40:
        return this._downPressed(event);

      // Escape key
      case 27:
        return this._toggleOptions();
    }
  },

  _upPressed(event) {
    if (this.state.index != -1) {
      this._handleFocusChange(this.state.index - 1);
    }
  },

  _downPressed(event) {
    if (this.state.index != getResults.counter - 1) {
      this._handleFocusChange(this.state.index + 1);
    }
  },

  _handleFocusChange(newIndex) {
    let newResults = [];
    for (let index in this.state.results) {
      if (_.isString(this.state.results[index].props.children)) {
        let newElt = React.cloneElement(this.state.results[index], {
          focused: index == newIndex,
        });
        newResults.push(newElt);
      } else {
        let children = [];
        for (let child in this.state.results[index].props.children) {
          let clonedChild = React.cloneElement(this.state.results[index].props.children[child], {
            focused: this.state.results[index].props.children[child].props.index == newIndex,
          });
          children.push(clonedChild);
        }
        let newElt = React.cloneElement(this.state.results[index], {
          children: children,
        });
        newResults.push(newElt);
      }
    }
    this.setState(
      {
        results: newResults,
        index: newIndex,
      },
      () => this._scrollToFocus(true)
    );
  },

  _scrollToFocus(scroll) {
    if (scroll) {
      if (this.state.index != -1) {
        var option = getFocusedNode();
        if (option) {
          // eslint-disable-next-line react/no-find-dom-node
          $(findDOMNode(this.resultList)).scrollTop(option.offsetTop - option.clientHeight - 4);
        }
      } else {
        // eslint-disable-next-line react/no-find-dom-node
        $(findDOMNode(this.resultList)).scrollTop(0);
      }
    }
  },

  _handleMouseEnter(event) {},

  render() {
    const {
      placeholder,
      label,
      onAdd,
      icons,
      children,
      name,
      readOnly,
      className,
      displayValue,
      disabled,
      style,
      selector,
    } = this.props;

    return (
      <Manager>
        <Reference>
          {({ ref }) => {
            if (selector) {
              const clone = React.cloneElement(selector, {
                onClick: this._canChoose() ? this._toggleOptions : undefined,
              });
              return <span ref={ref}>{clone}</span>;
            }
            return (
              <div ref={ref}>
                <div
                  id={this.props.id}
                  data-qa-id={`chooser-${name}`}
                  className={`${readOnly ? "entity-name" : className} \
                      ${this.state.text || !displayValue ? "" : " chooser-placeholder"}`}
                  onClick={this._canChoose() ? this._toggleOptions : undefined}
                  disabled={disabled}
                  readOnly={readOnly}
                  style={style}
                >
                  {this.state.text || (this._canChoose() ? placeholder : `No ${label}`)}
                </div>
              </div>
            );
          }}
        </Reference>
        {this._canChoose() &&
          this.state.show &&
          ReactDOM.createPortal(
            <Popper placement="bottom-start">
              {({ ref, style, placement }) => (
                <div ref={ref} style={{ zIndex: 9000, ...style }}>
                  <div
                    ref={(element) => (this.menu = element)}
                    className={`fl-chooser-menu ${this.state.show ? "" : "hidden"}`}
                  >
                    <div className="filter">
                      <img className="chooser-icon icon" src={searchIcon} />
                      {this.state.filterText ? (
                        <div title="Clear" className="clear" onClick={this._clearFilter}>
                          ×
                        </div>
                      ) : undefined}
                      <input
                        ref={(element) => (this.filterTextInput = element)}
                        placeholder="type to filter..."
                        type="text"
                        value={this.state.filterText}
                        onChange={this._setFilter}
                        onKeyUp={this._handleEnter}
                        onKeyDown={this._handleKeyDown}
                      />
                    </div>
                    <div className="tabs" />
                    <div className="results">
                      <div
                        className={`result-list ${icons ? "with-images" : ""}`}
                        ref={(element) => (this.resultList = element)}
                      >
                        {this.state.results}
                        {!this.state.results.length ? <div className="empty">no results</div> : undefined}
                      </div>
                    </div>
                    {onAdd ? (
                      <div className="add-new">
                        <button className="btn btn-block js-add" onClick={this._handleAdd}>
                          <span>Add</span>
                          <span>&nbsp;</span>
                          {this.state.filterText ? `\"${this.state.filterText}\"` : "new"}
                        </button>
                      </div>
                    ) : undefined}
                  </div>
                </div>
              )}
            </Popper>,
            document.querySelector("#dialog")
          )}
      </Manager>
    );
  },
});
